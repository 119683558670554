/* 정답표 */
@font-face {
    font-family: 'BookkMyungjo-Bd';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2302@1.0/BookkMyungjo-Bd.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}


.hrline {
    border: 1px solid #898989;
    margin-bottom: 40px;
    margin-top: 34px;
}

.an_text {
    font-size: 24px;
    text-align: center;
    position: relative;
    line-height: 53px;
    margin: 0 auto;
    border-top: 6px double;
    border-bottom: 6px double;
    font-family: 'Gothic A1', sans-serif;
    font-weight: 600;
}

.an_text span {
    padding: 0 38px;
}


.coan_tt02 {
    max-width: 142px;
    margin: 0 auto;
    padding: 10px 28px;
    font-size: 20px;
    text-align: center;
    background: #FFF;
    font-weight: bold;
    border: 2px solid;
    border-radius: 30px;
    font-family: 'Gothic A1', sans-serif;
    margin-top: 34px;
    margin-bottom: 25px;

}



.an_tb,
.an_tb02 {
    border-collapse: collapse;
    text-align: center;
    table-layout: fixed;
    border: 2px solid black;
    margin: 0 auto;
    font-family: 'BookkMyungjo-Bd';
}


.an_tb td,
.an_tb02 td {
    border: 1px solid black;
    padding: 6px 11px;
    width: 40px;
    font-size: 20px;
    background-color: #FFF;
}

.tbbox {
    max-width: 400px;
    margin: 0 auto;
    margin-top: 27px;
    margin-bottom: 27px;
}

.an_tb02 {
    width: 100%;
}

.td_1 {
    background-color: #cbcbcb !important;
    font-style: italic;
}

.td_2 {
    width: 4px !important;
    background-color: #cbcbcb !important;
    font-style: italic;

}


.td_3 {
    width: 100px !important;
    line-height: 29px;

}