/* 듣기 평가 */
.q_sound,
.q_sound02,
.q_sound03,
.q_sound04,
.q_sound05 {
    display: flex;
    background: #FFF;
    max-width: 380px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border-radius: 47px;
    border: 1px solid;
    column-gap: 7px;
    font-family: 'Gothic A1', sans-serif;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 16px;
    padding: 6px;
    padding-right: 13px;

}

.playicon {
    width: 35px;
}

/* 문항 */


u {
    text-decoration: underline;
    text-underline-offset: 5px;
}

.answer-label a_label {
    display: none;
}

.q_num {
    font-size: 25px;
    font-weight: bold;
    font-family: 'YESMyoungjo-Regular';
    margin-bottom: 11px;
}

.q_box {
    font-family: 'YESMyoungjo-Regular';
    color: #000;
    font-size: 18px;
    line-height: 29px;
    margin-bottom: 25px;
    word-break: normal;
}

.q_box02 {
    font-family: 'YESMyoungjo-Regular';
    font-size: 18px;
    padding: 17px;
    border: 1px solid;
}


.q_pic_01 {
    max-width: 400px;
    margin: 0 auto;
}

.q_pic_01>img {
    width: 100%;
}

.pvbox {
    font-family: 'Gothic A1', sans-serif;
    border: 1px solid black;
    max-width: 224px;
    padding: 14px 20px;
    margin: 0 auto;
    font-size: 14px;
    margin-top: 16px;
}


.pvbox02 {
    font-family: 'YESMyoungjo-Regular';
    border: 1px solid black;
    max-width: 500px;
    padding: 16px;
    margin: 0 auto;
    font-size: 18px;
    margin-top: 16px;
    line-height: 28px;

}

.q_pvtt {
    text-align: center;
    position: relative;
    top: -23px;
    background: #efede7;
    width: 62px;
    margin: 0 auto;
    margin-bottom: -20px;
}

.pvbox_02 {
    font-family: 'YESMyoungjo-Regular';
    border: 1px solid black;
    max-width: 500px;
    padding: 13px;
    text-align: center;
    margin: 0 auto;
    font-size: 18px;
    margin-top: 29px;
}

.pvbox_03 {
    font-family: 'YESMyoungjo-Regular';
    border: 1px solid black;
    max-width: 500px;
    padding: 13px;
    text-align: center;
    margin: 0 auto;
    font-size: 18px;
    margin-top: 29px;
}



.q_pvtt_02 {
    text-align: center;
    position: relative;
    top: -23px;
    background: #efede7;
    width: 62px;
    margin: 0 auto;
    margin-bottom: -20px;
}

.q_pvtt_03 {
    text-align: left;
    line-height: 28px;

}


.q_pvtt_04 {
    font-family: 'YESMyoungjo-Regular';
    font-size: 18px;

}

.q_pvtt_05 {
    border: 1px solid;
    padding: 17px 35px;
    margin: 0 auto;
    margin-bottom: 19px;
}

.q_pvtt_05 p {
    font-weight: bold;
    margin: 0;
    margin-bottom: -10px;
}


.q_box>u {
    text-underline-offset: 6px;
}


.q_picbox,
.q_pic_01,
.ldbox {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/* 사진 자료 */
.q_picbox {
    border: 1px solid;
    max-width: 500px;
    margin: 0 auto;
    font-family: 'YESMyoungjo-Regular';
    margin-bottom: 10px;
    line-height: 0px;

}

.q_picbox>img {
    width: 100%;
}

.q_picbox>p {
    text-align: center;
    line-height: 12px;
    border-bottom: 1px solid;
    margin: 0;
    padding: 12px;
    font-size: 18px;
}

.pzbox {
    font-family: 'YESMyoungjo-Regular';
    padding: 21px;
    border: 1px solid;
    font-size: 18px;
    line-height: 28px;

}

.pztt p {
    text-indent: -44px;
    padding-left: 44px;
    margin: 0;
}

.table {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    border-collapse: separate;
    border: 1px solid black;
    font-size: 15px;
}

.row-hd {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid black;
    background: #cdcdcd;

}

.row-hd .cell,
.row-hd .cell-last {
    justify-content: center;
}

.row {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid black;

}

.row-last {
    display: flex;
    flex-direction: row;
    border-bottom: 0px;
}


.cell,
.cell_num,
.cell_date {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    border-right: 1px solid black;
    word-break: break-all;

}


.cell_num {
    justify-content: center;
    max-width: 40px;
}

.cell_date {
    justify-content: center;
    max-width: 64px;
}

.cell-last {
    width: 100%;
    max-width: 100px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 0px;
}

.cell_date {
    justify-content: center;
}


.blankBox {
    padding: 0px 25px;
    margin-left: 5px;
    margin-right: 3px;
    border: 1px solid;
}


/* .indent_text {
    text-indent: -50px;
    padding-left: 50px;
    margin: 0;
} */

.indent_text {
    text-indent: -24px;
    padding-left: 24px;
    margin: 0;
}

/* 타이머 */
@font-face {
    font-family: 'DOSSaemmul';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_eight@1.0/DOSSaemmul.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.timer {
    display: flex;
    justify-content: right;
    align-items: center;
    font-family: 'DOSSaemmul';
    margin-bottom: 25px;
}

img.alarmicon {
    width: 20px;
    margin-top: -4px;
    margin-right: 3px;
}