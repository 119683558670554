header {
    display: grid;
    align-items: center;
    justify-items: center;
}

header.main_hd {
    margin-bottom: 27px;
}

.hd02 {
    margin: 30px 0px;
}

.test_title01 {
    font-family: 'Cafe24SsurroundAir';
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    width: 100%;
}

.test_title02 {
    font-family: 'HANAMDAUM';
    font-size: 35px;
    margin: 11px 0;
    text-align: center;
    width: 100%;

}

.test_title03 {
    font-family: 'Cafe24SsurroundAir';
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    width: 100%;
}

.test_title04 {
    font-family: 'HANAMDAUM';
    font-size: 41px;
    margin: 11px 0;
    text-align: center;
    width: 100%;
}


/* main header */

.main_hd02 {
    display: flex;
    width: 100%;
    margin-top: 13px;
    margin-bottom: 32px;
    justify-content: space-between;
    align-items: center;

}

.hdtt_01 {
    font-family: 'YESMyoungjo-Regular';
    font-weight: bold;
    font-size: 17px;
    padding: 12px 14px;
    line-height: 8px;
    height: 8px;
    border-radius: 30px;
    border: 1px #000 solid;
}

.hdlogo {
    display: flex;
    align-items: center;
}