/* Questions.css */
.progress-bar {
  max-width: 600px;
  height: 4px;
  border: 1px solid #000;
  overflow: hidden;
  margin-bottom: 22px;

}

.progress {
  background-color: #3f3f3f;
  border-right: 1px #000 solid;
  height: 100%;
  transition: width 0.5s ease-in-out;
}

.q_contain {
  padding: 10px;

}



/* 선택지 */
.optionsbox {
  font-family: 'YESMyoungjo-Regular';
  line-height: 42px;
  font-size: 18px;
  padding: 10px;

}

input.rdbtn {
  display: none;
}

input.rdbtn:checked~span {
  font-weight: bold;
  color: #000;
}

.opt_checked {
  position: absolute;
}

/* 체크된 라디오 버튼에 따라 .opt_checked 표시 */
.rdbtn:checked~.option-number .opt_checked {
  display: block;
  font-weight: bold;
  color: #000;
}

.rdbtn:checked~.option-number {
  font-weight: bold;
  color: #000;
  border: 2px #000 solid;
}



/* 이전, 다음 버튼 */
.prvnextbtnbox {
  font-family: 'Gothic A1', sans-serif;
  display: flex;
  justify-content: space-between;
  max-width: 600px;
  align-items: center;
  gap: 9px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 70px;
}

.prvbtn,
.nextbtn {
  font-family: 'Gothic A1', sans-serif;
  padding: 5px 16px;
  font-size: 15px;
  font-weight: 500;
  background: #FFF;
  border: 1px #000 solid;
  border-radius: 60px;
}

.nextbtn:disabled {
  background: #c7c7c7;
  border: 1px #595959 solid;
}

.qnumbox {
  font-size: 20px;
  text-align: center;
  padding: 6px 11px;
  font-family: 'YESMyoungjo-Regular';
}

.prvbtn:active,
.nextbtn:active {
  background: #bfbfbf;
}

/* 단답형 */

.asform {
  width: 100%;
  max-width: 164px;
  background: none;
  border: none;
  font-size: 20px;
  border-bottom: 2px #000 solid;
  font-weight: bold;
  font-family: 'Gothic A1', sans-serif;
  text-align: center;
  margin-left: 16px;
  border-radius: 0px;

}

.answer {
  font-family: 'Gothic A1', sans-serif;
  font-weight: 700;
  margin-bottom: 25px;

}

.answercontain {
  display: grid;
  justify-content: center;
  margin-top: 23px;
}

span.answer-label {
  font-size: 20px;
  font-weight: bold;
}

.q_type {
  max-width: 100px;
  text-align: center;
  padding: 5px 19px;
  border: 2px solid;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 3px;
  margin-bottom: 21px;
  font-family: 'Gothic A1', sans-serif;
}

.q_type_l {
  max-width: 600px;
  text-align: left;
  padding: 12px;
  border: 2px solid;
  font-size: 13px;
  letter-spacing: 1px;
  margin-bottom: 21px;
  font-family: 'HANAMDAUM';
}

.q_last {
  max-width: 600px;
  text-align: left;
  padding: 12px;
  border: 1px solid;
  font-size: 15px;
  letter-spacing: 1px;
  margin-top: 39px;
  font-family: 'Gothic A1', sans-serif;
}