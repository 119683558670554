@font-face {
    font-family: 'SBAggroB';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SBAggroB.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'neurimboGothicRegular';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2304-01@1.0/neurimboGothicRegular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'insungitCutelivelyjisu';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_11-01@1.0/insungitCutelivelyjisu.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

.loading-overlay {
    display: flex;
    position: fixed;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background: rgb(239 237 231);
    justify-content: center;
    align-items: center;
    color: black;
    z-index: 999;
    font-family: 'neurimboGothicRegular';
    font-size: 31px;
    font-weight: 100;
}



.loading-overlay>p {
    margin: 0;
}

.ldbox {
    margin-top: 18px;
    max-width: 250px;
}

.ldbox img {
    width: 100%;
}

.cardname {
    text-align: center;
    font-size: 21px;
    font-family: Cafe24SsurroundAir;
    margin: 16px auto;
    font-weight: bold;
}

.result {
    position: fixed;
    font-family: 'Gothic A1', sans-serif;
    width: 500px;
    height: 790px;
    border-radius: 20px;
}


/* 우정증 */
.result_02 {
    position: absolute;
    bottom: 283px;
    text-align: center;
    margin: 0 auto;
    width: 500px;
    font-size: 28px;
    font-weight: bold;
    color: #2b2b2b;
}

.uj_pic {
    position: absolute;
    width: 230px;
    height: 300px;
    top: -231px;
    left: 28px;
}

.uj_no {
    position: relative;
    right: -340px;
    top: -62px;
    font-size: 20px;
    width: 135px;
    height: 30px;
    font-family: 'SBAggroB';
    font-weight: 700;
}

.uj_sc {
    position: relative;
    right: -354px;
    top: -58px;
    font-size: 21px;
    width: 114px;
    height: 29px;
    text-align: center;
    font-family: 'insungitCutelivelyjisu';
}


.uj_gd {
    position: relative;
    right: -354px;
    top: -44px;
    font-size: 21px;
    line-height: 31px;
    width: 114px;
    height: 34px;
    text-align: center;
    font-family: 'insungitCutelivelyjisu';
}

.uj_nm {
    position: relative;
    width: 304px;
    height: 38px;
    left: 139px;
    top: -6px;
    text-align: center;
    font-family: 'insungitCutelivelyjisu';
}

.uj_ps {
    font-size: 23px;
    position: relative;
    width: 306px;
    height: 41px;
    line-height: 43px;
    left: 150px;
    top: 15px;
    text-align: center;
    font-family: 'insungitCutelivelyjisu';
}

.uj_dt {
    position: relative;
    width: 500px;
    bottom: -205px;
    text-align: center;
    font-family: 'neurimboGothicRegular';
    font-weight: normal;
}

.idcardcontain {
    display: flex;
    margin: 0 auto;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

}

.idcardcontain img {
    max-width: 80%;
    border-radius: 17px;

}


.idbtn {
    font-size: 18px;
    font-weight: bold;
    position: relative;
    background: #FFF;
    border: 1px solid;
    border-radius: 50px;
    font-family: 'Gothic A1', sans-serif;
}

.idbtn:hover,
.ra_btn:hover,
.re_btn:hover {
    background: #d0d0d0;
    color: #3e3e3e;

}

.idbtn>a:hover {
    color: #3e3e3e;
}

.idbtn>a {
    padding: 15px 33px;
    text-decoration: none;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.idbtncontain {
    margin: 0 auto;
    display: table;
    text-align: center;
    margin-bottom: 20px;

}

.idcard_tt {
    text-align: center;
    font-family: 'Gothic A1', sans-serif;
    font-weight: 500;
    color: #000;
    font-size: 15px;
    margin-top: -8px;
}

.rt_btncontain {
    display: inline-grid;
    width: 100%;
    justify-content: center;
    font-family: 'Gothic A1', sans-serif;
    margin-top: 60px;

}

.ra_btn {
    padding: 16px 49px;
    background: #FFF;
    border: 1px solid;
    border-radius: 30px;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;

}

.re_btn {
    padding: 14px;
    background: #c9c9c9;
    border: 1px solid;
    font-size: 18px;
    font-weight: bold;
    margin: 28px 0px;
}