.namebox {
    max-width: 262px;
    display: flex;
    margin: 0 auto;
    font-family: 'Cafe24SsurroundAir';
    font-weight: bold;
    font-size: 20px;
    align-items: center;
    border: 1px #000 solid;

}

.namett {
    padding: 12px 12px;
    width: 62px;
    border-right: 1px #000 solid;
    text-align: center;

}


input.nametext {
    font-family: 'Cafe24SsurroundAir';
    font-weight: bold;
    font-size: 20px;
    padding: 12px;
    width: 100%;
    border: 0;
    background: none;
    text-align: center;
}

img.logosvg {
    width: 46px;
}

/* 수험 번호 */

.examnumbox {
    width: 328px;
    display: flex;
    margin: 0 auto;
    border: 1px #000 solid;
    font-family: 'Cafe24SsurroundAir';
    font-weight: bold;
    font-size: 20px;
    align-items: center;
    margin-top: 17px;

}

.examtt_01 {
    width: 83px;
    border-right: 1px #000 solid;
    text-align: center;
    padding: 6px 5px;

}

.numbox {
    border-right: 1px #000 solid;
    padding: 6px;
    text-align: center;

}

.numbox_end {
    padding: 6px;
}


.examsignbox {
    font-family: 'Gothic A1', sans-serif;
    font-weight: 500;
    border: 1px #000 solid;
    max-width: 440px;
    margin: 0 auto;
    margin-top: 25px;
    padding: 25px;
    font-size: 20px;
}

.signtext {
    background: #c1c1c1;
    max-width: 326px;
    padding: 8px;
    text-align: center;
    border: 1px #000 solid;
    margin-top: 10px;
    font-weight: 700;

}

.exlist {
    list-style-type: circle;
    padding-left: 13px;

}

.exlist>li {
    margin-bottom: 26px;
}

.startbtn {
    font-size: 21px;
    background: #fff;
    border: 1px #000 solid;
    padding: 15px 41px;
    border-radius: 30px;
    font-family: 'Gothic A1', sans-serif;
    font-weight: 500;
}

.startbtn:active {
    background: #a5a5a5;

}


.startbtn:disabled {
    background: #a5a5a5;
    color: #757575;
}

.mainbtnbox {
    display: flex;
    justify-content: center;
    margin: 48px;

}

.cprbox {
    display: flex;
    font-size: 21px;
    font-family: 'Cafe24SsurroundAir';
    font-weight: 600;
    align-items: center;
    justify-content: center;
    margin-top: 66px;
    margin-bottom: 17px;
}


.bloglink {
    font-weight: 700;
    cursor: pointer;
    margin-left: 5px;

}

.bloglinkbox {
    position: absolute;
    background: #FFF;
    padding: 13px 18px;
    border: 1px #000 solid;
    font-size: 15px;
    font-weight: 500;
    margin-left: 127px;
}

.bloglinkbox a {
    text-decoration: none;
    color: #000;
    font-weight: 700;
}


@media only screen and (min-width:200px) and (max-width:480px) {
    .signtext {
        text-align: left;
        padding: 8px 17px;
    }

}