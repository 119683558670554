.q_opt {
    list-style-type: none;
    padding: 0;
}



.anchecksvg01,
.anchecksvg02 {
    width: 35px;
    position: absolute;
    margin-left: 20px;
    margin-bottom: 16px;
}



.seleclist {
    font-family: 'YESMyoungjo-Regular';
    line-height: 27px;
    font-size: 18px;
    display: inline-flex;
}

.seleclist>span {
    margin-bottom: 10px;
}

.seleclist>.option-number {
    margin-top: 4px;
}

.ckbox02 {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
}

/* 정답일 때의 스타일 */
.correct-option-number {
    color: red;
    font-weight: bold;
    border: 2px red solid !important;
}

.correct-op-text {
    color: red;
    font-weight: bold;
    position: relative;

}

/* 사용자가 선택한 옵션일 때의 스타일 */
.user-selected-option-number {
    color: #333333;
    font-weight: bold;
    border: 2px #333333 solid !important;
}

.user-selected-op-text {
    color: #333333;
    font-weight: bold;
    position: relative;
}

.option-number {
    padding: 5px;
    border-radius: 50%;
    border: 1px #000 solid;
    width: 10px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

img.checksvg {
    width: 35px;
    opacity: 0.8;
    margin-left: 9px;
    margin-bottom: 10px;
}

.O_svg,
.X_svg {
    width: 65px;
}


.correct-answer,
.incorrect-answer {
    position: absolute;
    margin-top: -7px;
    margin-left: -11px;
    opacity: 0.7;
    pointer-events: none;
}


.optiontext {
    display: inline-flex;
    align-items: baseline;
    cursor: pointer;
    line-height: 27px;
}

.ra_tt_box {
    display: flex;
    align-items: center;
    justify-items: center;
    margin-bottom: 12px;

}

.coan_tt {
    max-width: 142px;
    margin: 0 auto;
    padding: 10px 28px;
    font-size: 20px;
    text-align: center;
    background: #FFF;
    font-weight: bold;
    border: 1px solid;
    border-radius: 30px;
    font-family: 'Gothic A1', sans-serif;
}

.ancontain {
    font-family: 'Gothic A1', sans-serif;
    display: flex;
    align-items: center;
    background: #d7d7d7;
    padding: 8px 8px;
    margin-top: 35px;

}

.multi_num {
    padding: 5px;
    border-radius: 50%;
    border: 1px #000 solid;
    width: 8px;
    height: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    text-align: center;
    font-size: 15px;
}

.explaincontain {
    font-family: 'YESMyoungjo-Regular';
}

.exp_tt,
.exp_tt02 {
    font-family: 'Gothic A1', sans-serif;
    font-weight: bold;
    font-size: 14px;
    background: #6d6d6d;
    width: 67px;
    color: #FFF;
    text-align: center;
    padding: 3px 3px;
    border-radius: 6px;
    margin-bottom: 20px;
    line-height: 20px;
}

.exp_tt02 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.expbox {
    font-family: 'YESMyoungjo-Regular' !important;
    font-weight: normal !important;
    margin-top: -10px;
    line-height: 25px;

}

button.rebtn {
    position: absolute;
}

.leftsvg:hover {
    background: #36363621;
    border-radius: 66px;
}


img.leftsvg {
    width: 57px;
    margin-left: -18px;
    transition: all 0.2s;
}

.rebtncontain {
    height: 60px;
}

.qn_contain {
    padding: 10px;
}

.cr_tt {
    margin-right: 8px;
    font-weight: bold;
}


.user_as {
    font-family: 'Gothic A1', sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin: 20px 0px;
}

.user_as>span {
    font-weight: 600;
}



/* 링크 */
.linkbox {
    font-family: 'Gothic A1', sans-serif;
    border: 1px solid;
    border-radius: 6px;
    padding: 11px 16px;
    background: #e3e3e3;
    font-size: 14px;
    width: 100%;

}

.linkbox:hover {
    background: #c1c1c1;
}

.linkbox>p {
    margin: 0;
    padding: 5px;
    color: #000;
}


.linkbox>span {
    display: flex;
    align-items: center;
    gap: 7px;
    justify-content: left;
    color: #5d5d5d;
    max-width: 250px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.link01 {
    text-decoration: none;
    display: flex;
    max-width: 400px;
    color: #6a6a6a;
    margin-bottom: 20px;
}

.snsicon {
    width: 25px;
}